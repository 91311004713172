@import "./variables.scss";

@media screen and (max-width: $mobile-breakpoint) {
    button.btn-round {
        width: 50px;
        height: 50px;
        padding: 10px;
        font-size: 1em;
    }

    div {
        &.logo-text {
            display: none;
            // width: 100px;
            // right:10px;
        }
        &.version-info {
            bottom: 2px;
            left: 3px;
            font-size: 0.5em;
        }
    }
    // button:focus {
    //     outline: none;
    // }
}
