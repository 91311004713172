/** colors **/
$red: #fc3d61;
$red-dark: darken($red, 10%);
$orange: #f7a234;
$purple: #d88cf2;
$purple-dark: #8e44ad;
$green: #24d97f;
$green-dark: darken($green, 10%);
$blue: #3366ff;
$blue-dark: darken(#2759d6, 10);
$blue-light: #9cd8eb;
$blue-neon: #00ffff;
$yellow: #f1c40f;
$yellow-light: #fcdd50;
$lime: #60e963;
$salmon: #ffece3;
$salmon-dark: darken($salmon, 30%);
$gray: #cac7d8;
$gray-dark: #636371;
$gray-darker: #33363e;
$gray-darkest: darken($gray-darker, 17%);
$gray-light: lighten($gray, 7%);
$gray-lighter: lighten($gray, 14%);
$gray-lightest: lighten($gray, 17%);
$gold: #c39953;

/** components **/

$btn-round-defaut-bkg: transparentize(black, 0.2);
$btn-round-defaut-bkg-gradient-start: transparentize(black, 0.4);
$btn-round-defaut-bkg-gradient-start-active: transparentize(black, 0.1);

$btn-round-width:  0.266em * 15; // 4px / 15em (font size)
$btn-round-width-lg: 0.266em * 18;
$btn-round-width-sm: 0.266em * 12;
$btn-round-width-xs: 0.266em * 8.3;
$btn-round-width-xxs: 0.266em * 7;

// $btn-round-width: 4px * 15;
// $btn-round-width-lg: 4px * 18;
// $btn-round-width-sm: 4px * 12;
// $btn-round-width-xs: 4px * 9;
// $btn-round-width-xxs: 4px * 7;

$stream-controls-bottom: 20px;
$stream-controls-height: 4px * 12; // $btn-round-width-sm;

$device-settings-tile-height: 200px;
$device-settings-card-height: 495px;

$captions-container-height: 155px;

$talkdesk-header-height: 32px;

$spinner-size-xs-width: 28px;

$mobile-breakpoint: 599px;
$tablet-breakpoint: 959px;
